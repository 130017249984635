<template>
<div>
  <div :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '80vh' }">
    <a-spin
      tip="前往調查表單中"
      :indicator="indicator"
    />
  </div>
</div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import liff from '@line/liff/dist/lib'

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();

    /* Liff */
    const initLiff = (async () => {
      try {
        await liff.init({ liffId: process.env.VUE_APP_LIFF_INSTANT_SURVEY });
      } catch (err) {
        console.log(err.code, err.message);
      }
    });

    /* Mounted */
    onMounted(async () => {
      await initLiff();

      const courseId = route.query.course_id;
      const id = route.query.id;

      router.push({
        name: 'LiffInstantSurveyLiffPage',
        params: {
          course_id: courseId,
          id: id
        }
      });
    });
  }
})
</script>